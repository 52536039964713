@import "_exam-progress-bar.less";
.optionBorderAnimationKeyframes(@name, @color) {
	@keyframes @name {
		0% {border-image: linear-gradient(170deg, @color   0%, rgba(0,0,0,0)   0% 100%) 1;}
		5% {border-image: linear-gradient(170deg, @color   5%, rgba(0,0,0,0)   5% 100%) 1;}
		10% {border-image: linear-gradient(170deg, @color  10%, rgba(0,0,0,0)  10% 100%) 1;}
		15% {border-image: linear-gradient(170deg, @color  15%, rgba(0,0,0,0)  15% 100%) 1;}
		20% {border-image: linear-gradient(170deg, @color  20%, rgba(0,0,0,0)  20% 100%) 1;}
		25% {border-image: linear-gradient(170deg, @color  25%, rgba(0,0,0,0)  25% 100%) 1;}
		30% {border-image: linear-gradient(170deg, @color  30%, rgba(0,0,0,0)  30% 100%) 1;}
		35% {border-image: linear-gradient(170deg, @color  35%, rgba(0,0,0,0)  35% 100%) 1;}
		40% {border-image: linear-gradient(170deg, @color  40%, rgba(0,0,0,0)  40% 100%) 1;}
		45% {border-image: linear-gradient(170deg, @color  45%, rgba(0,0,0,0)  45% 100%) 1;}
		50% {border-image: linear-gradient(170deg, @color  50%, rgba(0,0,0,0)  50% 100%) 1;}
		55% {border-image: linear-gradient(170deg, @color  55%, rgba(0,0,0,0)  55% 100%) 1;}
		60% {border-image: linear-gradient(170deg, @color  60%, rgba(0,0,0,0)  60% 100%) 1;}
		65% {border-image: linear-gradient(170deg, @color  65%, rgba(0,0,0,0)  65% 100%) 1;}
		70% {border-image: linear-gradient(170deg, @color  70%, rgba(0,0,0,0)  70% 100%) 1;}
		75% {border-image: linear-gradient(170deg, @color  75%, rgba(0,0,0,0)  75% 100%) 1;}
		80% {border-image: linear-gradient(170deg, @color  80%, rgba(0,0,0,0)  80% 100%) 1;}
		85% {border-image: linear-gradient(170deg, @color  85%, rgba(0,0,0,0)  85% 100%) 1;}
		90% {border-image: linear-gradient(170deg, @color  90%, rgba(0,0,0,0)  90% 100%) 1;}
		95% {border-image: linear-gradient(170deg, @color  95%, rgba(0,0,0,0)  95% 100%) 1;}
		100% {border-image: linear-gradient(170deg, @color 100%, rgba(0,0,0,0) 100% 100%) 1;}
	}
}
#practice-exam.exam-container.exam-container--practice-module {
	h2 {
		text-align: left;
		margin-left: 0;
		margin-right: 0;
	}
	
	.exam__subheader {
		text-align: left;
		margin-top: 32px;
		margin-bottom: 32px;
		@media(min-width: @screen-laptop){
			margin-bottom: 60px;
		}
	}
}

#practice-exams {
	min-height: 550px;
}

.practice-questions-module {
	display: flex;
	flex-direction: column;
	padding: 16px 15px 25px 15px;
	width: unset;
	//max-width: @max-section-content-width;
	background-color: @color-white;
	border-radius: 6px;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
	overflow: hidden;
	text-align: left;
	margin-top: 30px;

	@media (min-width: @screen-tablet) {
		padding: 50px 45px;
		margin-left:  auto;
		margin-right:  auto;
		width: 100%;
	}
	
	&.practice-questions-module--results {
		flex-direction: column;
		gap: 30px;
		
		@media (min-width: @screen-tablet) {
			flex-direction: row;
			gap: 75px;
		}
	}
	
	fieldset {
		border: none;
	}
}
.questionContainer {
	@media(max-width: @screen-mobile-max){
		order:2
	}
}
.practice-questions-module__button-container {
	 @media(max-width: @screen-mobile-max){
		 order:3
	 }
	text-align: right;
 }
.practice-questions-module__simple-header {
	padding-bottom: 12px;
}
.practice-questions-module__header {
	order: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 20px;
	margin-top: 20px;
	border-bottom: none;
	
	@media (min-width: @screen-tablet) {
		order: unset;
		flex-direction: row;
		gap: 10px;
		margin-top: 0;
		border-bottom: 1px solid @color-gray-1;
	}
}

.practice-questions-module__progress {
	flex: 1;
	width: 100%;
	max-width: unset;
	margin-bottom: 10px;
	
	@media (min-width: @screen-tablet) {
		margin-bottom: 0;
		width: unset;
		max-width: 423px;
	}
}

.practice-questions-module__counter {
	font-size:20px;
	font-weight: @font-weight-bold;
}

.practice-questions-module__complete-text {
	display: none;
	
	@media (min-width: @screen-tablet) {
		display: block;
		font-size: 16px;
		font-weight: @font-weight-semibold;
		line-height: 30px;
	}
}

.practice-questions-status {
	align-self: flex-start;
	display: flex;
	flex-direction: column;
	width: 100%;
	text-align: center;
	align-items: center;
	@media (min-width: @screen-tablet) {
		align-self: stretch;
		text-align: left;
		width: auto;
	}
}

.practice-questions-status__correct, .practice-questions-status__missed {
	display: flex;
	align-items: center;
	font-weight: 600;
}

.practice-questions-status__icon {
	margin-right: 5px;
	
	&.icon-ok-sign {
		color: #8CC63E;
	}
	
	&.icon-remove-sign {
		color: @color-error;
	}
	
	img {
		width: 80%;
		margin-top: 5px;
	}
}

.practice-questions-module__button {
	.pill-button();
	width: auto;
	color: @color-brand;
	background: #FFFFFF;
	border: 2px solid @color-brand;
	font-weight: @font-weight-bold;
	margin-top: 30px;
	margin-bottom: 0;
	&:hover {
		color: #fff;
	}
}

.question-explanation-collapse.show {
	visibility: visible;
}

.question-explanation {
	padding: 18px;
	color: @color-gray-5;
	border-radius: 10px;
	overflow: hidden;
}

.question-explanation__title {
	margin-bottom: 10px;
	font-size: 18px;
	font-weight: @font-weight-semibold;
	line-height: 30px;
}

.question-explanation__content {
	line-height: 27px
}

.final-score {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 26px 64px;
	background-color: @color-gray-e;
	border-radius: 6px;
	overflow: hidden;
}

.final-score__text {
	margin-bottom: 15px;
	font-size: 20px;
	line-height: 28px;
	
	@media (min-width: @screen-tablet) {
		font-size: 24px;
		line-height: 32px;
	}
}

.dial-gauge(228px, @color-white, @color-white);

.dial-gauge {
	position: relative;
	margin: 0 auto;
}

.dial-failure {
	fill: #D45333;
}

.dial-success {
	fill: #8EC53F;
}

.dial-gauge-percentage {
	font-size: 60px;
	font-weight: @font-weight-bold;
	line-height: 80px;
	
	&.dial-gauge-percentage--failed {
		color: #D45333;
	}
	
	&.dial-gauge-percentage--passed {
		color: #8EC53F;
	}
}

.create-account-cta {
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: @color-gray-5;
}

.create-account-cta__title {
	margin-bottom: 15px;
	font-size: 24px;
	line-height: 34px;
	font-weight: @font-weight-bold;
	
	@media (min-width: @screen-tablet) {
		line-height: 42px;
	}
}

.create-account-cta__list {
	margin-bottom: 36px;
	font-size: 20px;
	line-height: 30px;
	padding-inline-start: 36px;
	
	@media (min-width: @screen-tablet) {
		font-size: 22px;
		line-height: 34px;
	}
}
.create-account-cta__risk-free {
	font-weight: @font-weight-semibold;
}
.create-account-cta__button {
	align-self: stretch;
	margin-bottom: 15px;
	font-size: 20px;
	
	@media (min-width: @screen-tablet) {
		align-self: flex-start;
	}
}

.create-account-cta__text {
	align-self: stretch;
	margin-left: 0;
	font-size: 16px;
	font-weight: @font-weight-semibold;
	text-align: center;
	
	@media (min-width: @screen-tablet) {
		align-self: flex-start;
		margin-left: 30px;
		text-align: left;
	}
}

.create-account-cta__bold {
	font-weight: normal;
	
	@media (min-width: @screen-tablet) {
		font-weight: @font-weight-semibold;
	}
}

.question {
	
	@verticalSpacing: 20px;
	
	border-top: 1px solid @color-gray-1;
	
	.prompt, .passage {
		margin: 0;
		padding-top: @verticalSpacing;
		font-size: 18px;
		
		h4 {
			line-height: 1.33;
			padding-right: 2.5em;
			color: @color-gray-5;
			display: inline-block;
		}
		
	}
	
	.passage {
		padding: 25px;
		border-radius: 10px;
		border: 2px solid @color-brand;
		margin: @verticalSpacing auto;
		max-width: 800px;
		
		.instructions {
			font-weight: @font-weight-semibold;
			font-size: 16px;
			text-align: center;
			padding-bottom: @verticalSpacing;
			display: block; // override .practiceExamContainer .instructions
		}
		
		.passage-text {
			table.imageplugin {
				margin: 0 auto;
				td, th {
					padding: 0;
				}
			}
			
			img {
				max-width: 100%;
			}
			
			:last-child {
				margin-bottom: 0;
			}
		}
		
		@media (min-width: @screen-tablet) {
			&.visible-tablet {
				display: none;
			}
		}
	}
	
	.prompt {
		.promptTitle {
			width: 80%;
			font-weight: normal;
			display: flex;
		}
		
		&-number {
			margin-right: 5px;
		}
		
		&-text {
			display: block;
			
		}
		
		img {
			margin: 0.75em 2.5em 0;
		}
	}
	
	.unansweredQuestion {
		width: 91px;
		font-size: 16px;
		font-weight: @font-weight-semibold;
		color: @color-highlight;
		text-align: center;
		margin-top: 5px;
		margin-left: 5px;
		padding: 10px;
	}
	
	@radioBtnSize: 1.25em;
	@inputMargin: 1em;
	
	.answersContainer, .assessment-options {
		position: relative;
		//.clearfix();
		margin: 0;
		padding: 0 0 10px;
		
		.assessment-options__instructions {
			border-bottom: 1px solid #EEEEEE;
			font-weight: @font-weight-semibold;
			color: @color-gray-4;
			padding-bottom: 5px;
		}
		
		&.free-response {
			.assessment-options__instructions {
				border-bottom: none;
			}
		}
		
		ul {
			list-style: none;
			margin-right: 20px;
			width: 100%;
			padding: 0 10px 0 0;
			@media (min-width: 600px) {
				display: grid;
				grid-template-columns: repeat(auto-fill, ~"minmax(max(45%, 350px), 1fr)");
			}
		}
		
		li {
			padding: 7px 0;
			margin: 0;
			
			@media (min-width: 600px) {
				margin-right: 5%;
				min-height: 63px;
			}
		}
		
		label {
			cursor: pointer;
			margin-left: 2.5em;
			//display: block;
			font-weight: normal;
			font-size: 16px;
			position: relative;
			
			&.no-cursor {
				&, input {
					cursor: text;
				}
			}
		}
		
		input:not([type="text"]) {
			cursor: pointer;
			float: left;
			margin-right: @inputMargin;
			
			&[type="radio"] {
				position: absolute;
				left: -2.25em;
				width: 20px;
				height: 20px;
			}
		}
		
		.free-response__label {
			@media (min-width: @screen-xs-max) {
				margin-left: 2.7em;
			}
			
			display: flex;
			align-items: baseline;
			column-gap: 5px;
			
			* > {
				flex-grow: 0;
			}
			
			input {
				//.form-control();
				width: 200px;
				
				&::placeholder, &:-ms-input-placeholder {
					color: @color-gray-3;
				}
			}
			
			@keyframes correctFadeIn {
				from {
					top: -15px;
					opacity: 0;
				}
				
				to {
					top: 0;
					opacity: 1;
				}
			}
			
			&.free-response__label--user-answer {
				animation-name: correctFadeIn;
				animation-duration: 2s;
				
				color: @color-gray-5;
				background: @color-gray-1;
				border-radius: 6px;
				width: 30%;
				height: 60px;
				padding: 5px;
			}
			
			&.free-response__label--correct {
				position: relative;
				animation-name: correctFadeIn;
				animation-duration: 2s;
				
				margin-top: 10px;
				color: @color-gray-5;
				background: @color-white;
				box-shadow: 0 0 5px 2px #8CC63E;
				border-radius: 6px;
				width: 30%;
				height: 60px;
				padding: 5px;
				
				.correct-answer {
					color: #8CC63E;
					position: absolute;
					bottom: 0;
					right: 0;
					margin: 0 5px 2px 0;
					font-weight: @font-weight-semibold;
					font-size: 10px;
				}
			}
		}
		
		.noSelection {
			//.pull-left();
			float:left;
			color: @color-error;
			font-weight: bold;
			margin-left: 10px;
			line-height: 50px;
		}
	}
	
	&.error {
		background: fade(@color-brand, 25%);
	}
	
	@media (max-width: @screen-mobile-max) {
		border-top: none;
		
		.prompt {
			padding-top: 5px;
			
			h4 {
				padding: 0;
			}
			
			&-number {
				text-align: left;
				font-weight: bold;
				font-size: 15px;
			}
			
			&-text {
				font-weight: normal;
				font-size: 18px;
				margin: 0;
			}
		}
		
		.answersContainer, .assessment-options {
			ul {
				padding: 0 !important;
				margin-left: 0;
				margin-right: 0;
			}
			
			li {
				margin: 0 0 6px;
				padding: 0 0 0 2em;
			}
			
			input {
				&[type="radio"] {
					position: absolute;
					left: @inputMargin;
					top: 50%;
					margin-top: -(@radioBtnSize/2);
				}
			}
			
			label {
				margin: 0;
				background: @color-gray-1;
				position: relative;
				border: 1px solid @color-gray-1;
				padding: .1em;
				border-radius: 10px;
			}
			
			p {
				margin: 0 7px;
			}
		}
	}
}

.assessment-options--multiple-choice, .assessment-options--multiple-select {
	.exam-question__options {
		padding-left: 0; // override ul default padding
		list-style-type: none;
		
		display: grid;
		grid-auto-flow: row;
		grid-gap: 10px;
		
		@media (min-width: @screen-laptop) {
			grid-gap: 30px;
		}
	}
	
	.exam-question__option {
		display: flex;
		align-items: center;
		border-radius: 6px;
		position: relative;
		overflow: hidden;
		z-index: 0;
		padding-left: 15px;
		
		background-color: rgba(0, 0, 0, 0);;
		transition: background-color 50ms ease-out;
		
		@media (min-width: @screen-laptop) {
			&:not(.no-cursor):hover {
				cursor: pointer;
				background-color: @color-gray-e;
				border-radius: 7px;
			}
		}
	}
	
	.exam-question__option--user-answer {
		animation: 600ms wrongOptionBorder linear 1;
		border: 2px solid @color-error;
		
		&::after {
			position: absolute;
			top: 0;
			right: 8px;
			z-index: -1;
			
			content: "Incorrect answer";
			color: @color-gray-5;
			font-size: 12px;
			font-weight: normal;
		}
	}
	
	.exam-question__option--correct-answer {
		animation: 600ms correctOptionBorder linear 1;
		border: 2px solid #8CC63E;
		
		&::after {
			position: absolute;
			top: 0;
			right: 8px;
			z-index: -1;
			
			content: "Correct answer";
			color: @color-gray-5;
			font-size: 12px;
			font-weight: normal;
		}
	}
	
	.exam-question__option-label {
		display: grid;
		grid-template-columns:  max-content 1fr;
		grid-template-rows:  1fr  max-content;
		grid-template-areas: "radio text" "radio image";
		grid-column-gap: 15px;
		
		align-items: center;
		user-select: none;
		cursor: inherit;
		flex-grow: 1
	}
	
	.exam-question__input {
		grid-area: radio;
		cursor: inherit;
		border-radius: 50%;
		@media (max-width: @screen-mobile-max) {
			margin: 0;
		}
	}
	
	input[type="radio"] {
		flex-shrink: 0;
		height: 20px;
		width: 20px;
		margin: 0;
		-webkit-appearance: none;
		background-image: none;
		border: 1px solid #ccc;
		border-radius: 50%;
		
		&:before {
			content: '';
			display: block;
			width: 12px;
			height: 12px;
			border-radius: 50%;
		}
		
		&:checked {
			// we only care about these styles when checked
			display: grid;
			place-items: center;
			
			&::before {
				background: @color-brand;
			}
			
			&:disabled::before {
				background: @color-gray-2;
			}
		}
	}
	
	.exam-question__option-text {
		grid-area: text;
		padding: 15px 15px 15px 0;
		
		p {
			margin-bottom: 0
		}
	}
	
	.exam-question__option-img {
		grid-area: image;
	}
}

.question .answersContainer ul,
.question .assessment-options ul {
	
	@media (min-width: @screen-tablet) {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
}

.question .answersContainer li,
.question .assessment-options li {
	
	@media (min-width: @screen-tablet) {
		float: none;
		width: unset;
	}
}

.questionContainer.show-results li input[type="radio"] {
	margin-left: 0;
}

.questionContainer.show-results li:before {
	bottom: 10px;
	right: 15px;
	font-size: 14px;
	
	@media (min-width: @screen-tablet) {
		bottom: 3px;
		right: 6px;
	}
}

.questionContainer.show-results li.correct-answer {
	box-shadow: none;
	
	@media (min-width: @screen-tablet) {
		box-shadow: 0 0 8px #8CC63E;
		border: 1px solid #8CC63E !important;
	}
}

.questionContainer.show-results li.correct-answer label {
	
	@media (min-width: @screen-tablet) {
		box-shadow: none;
		border: none !important;
	}
}

.questionContainer.show-results li.user-answer {
	background-color: transparent;
	
	@media (min-width: @screen-tablet) {
		background-color: @color-gray-e;
	}
}

.questionContainer.show-results li.user-answer label {
	background-color: @color-gray-e;
	
	@media (min-width: @screen-tablet) {
		background-color: transparent;
	}
}

.question {
	border-top: none;
}

.prompt {
	padding-top: 0;
	margin-bottom: 30px !important;
	line-height: 28px;
	
	@media (min-width: @screen-tablet) {
		margin-bottom: 0;
	}
	
	.promptTitle {
		margin: 0;
		color: @color-gray-5 !important;
	}
}

.prompt-number, .prompt-text {
	font-size: 18px !important;
}

.prompt-number {
	font-weight: @font-weight-bold !important;
}

.prompt-text {
	font-weight: @font-weight-semibold !important;
}

.assessment-options li {
	margin-bottom: 10px !important;
	
	@media (min-width: @screen-tablet) {
		margin-bottom: unset;
	}
}

.assessment-options label {
	border: 1px solid @color-gray-3 !important;
	
	@media (min-width: @screen-tablet) {
		border: none !important;
	}
}

.questionPassage {
	font-size: 18px;
}
.img-responsive {
	display: block;
	max-width: 100%; // Part 1: Set a maximum relative to the parent
	height: auto
}
