@import (reference) "../global/components/expertContributions.less";
@import (reference) "../mixins/_styleResets.less";
@import (reference) "_variables.less";
@import "../global/components/_dial-gauge.less";
@import "../global/components/_plainExam.less";
@import "../global/components/_practice-questions-module.less";


.homepage {
	@import "pages/_home.less";
}

.suite {
	header.hero {
		background-color: @color-blue-5;

		background-image: url("/resources/img/77/woman-with-tablet.png"),
		url("/resources/img/77/orange-blossom-lg.svg"),
		url("/resources/img/77/orange-blossom-sm.svg");

		text-align: left;

		background-repeat: no-repeat, no-repeat, no-repeat;
		background-size: ~"calc(min(370px, 30%))", 813px, 539px;

		background-position:
		 right ~"calc(max(45px, ((100vw - @{max-width-hero}) / 2) + 15px))" bottom -60px,
		 top 40px right ~"calc(min(300px, -270px - @{screen-laptop} + 100vw))",
		 bottom -100px left ~"calc(max(-130px, ((100vw - @{max-width-hero}) / 2) - 130px))";

		min-height: 500px;

		section.content > * {
			max-width: ~"calc(min(100%, 680px))";
		}
	}
}

.individual-test {
	.passage {
		background-color: @color-gray-e;
		border-radius: 30px;
	}
	
	header.hero {
		position: relative;
		background-image: url("/resources/img/77/woman-at-desk-bw.jpg");
		background-size: ~"calc(min(100vw, @{max-width-hero}))";
		background-repeat: no-repeat;
		background-position: bottom center;
		margin-top: -50px;
		padding-top: @spacing-standard + 50px;
		z-index: -1;

		// this should be a mixin, but it's doing really weird things with the compilation for some reason.
		&:after {
			display: block;
			position: absolute;
			content: "";
			height: 0;
			width: ~"calc(min(100% - 60px, @{max-width-hero}))";
			left: ~"calc(max(30px, 50% - @{max-width-hero} / 2))";
			bottom: 0;
			border-bottom: 1px solid @color-blue-2;
		}
	}

	.start-test {
		margin-bottom: 60px;
		margin-top: 15px;
	}

	.practice-exam__question:not(:first-child) {
		margin-top: 120px;
	}

	.exam-image img {
		width: 120px;
	}

	.practice-exam-key li:not(:last-child) {
		margin-bottom: 30px;
	}
}

.info {
	header.hero {
		&:after {
			display: block;
			position: absolute;
			content: "";
			height: 0;
			width: ~"calc(min(100% - 60px, @{max-width-hero}))";
			left: ~"calc(max(30px, 50% - @{max-width-hero} / 2))";
			bottom: 0;
			border-bottom: 1px solid @color-blue-2;
		}

		background-image: url("/resources/img/77/student-teacher-bookcase.jpg");

		padding-bottom: @spacing-standard + 120px;
		background-position: bottom center;
		background-size: 120px;
		background-repeat: no-repeat;

		@media (min-width: @screen-tablet) {
			padding-bottom: @spacing-large;
			background-position: right ~"calc(max(30px, (100vw - @{max-width-hero}) / 2))" bottom;
			background-size: contain;
		}
	}
}

.index {
	header.hero {
		&:after {
			display: block;
			position: absolute;
			content: "";
			height: 0;
			width: ~"calc(min(100% - 60px, @{max-width-hero}))";
			left: ~"calc(max(30px, 50% - @{max-width-hero} / 2))";
			bottom: 0;
			border-bottom: 1px solid @color-blue-2;
		}
	}
}

.expert-contributions-container {
	.expert-contributions(@color-gray-e, @color-gray-8, @color-link);
	
	.view-more-state ~ .view-more-trigger:after {
		color: @color-white !important;
		background-color: @color-orange-1 !important;
		border-radius: 50%;
	}
}

.error--body {
	text-align: center;
	padding: 30vh 0;
	
	@media (min-width: @screen-laptop) {
		padding: 36vh 0;
	}
}
