@import (reference) "../../mixins/_icons.less";
@import (reference) "../../mixins/_styleResets.less";


.exam-container {
	#exam-instructions {
		margin-top: 30px;
		text-align: center;
	}
	
	aside {
		display: flex;
		justify-content: center;
		grid-column-gap: 15px;
		
		@media (max-width: @screen-mobile) {
			display: flex;
			flex-direction: column;
			grid-row-gap: @spacing-paragraph;
			align-items: center;
		}
		
		#resetFormButton {
			display: none;
		}
	}
	
	label, input {
		cursor: pointer;
		flex-basis: 100%;
	}
	
	.results-display {
		display: none;
		margin-top: @spacing-standard;
		text-align: center;
		
		#sign-up-button {
			margin-top: @spacing-standard;
		}
	}
	
	.form-group.validate-form .results-display {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 40px;
	}
	
	.form-group.validate-form .key {
		border: 1px solid @color-practice-exam-success !important;
		padding: 5px;
	}
	
	.form-group.validate-form .checked {
		background-color: #E1E1E1;
		padding: 5px;
	}
	
	.form-group.validate-form #seeResultsButton {
		display: none;
	}
	
	.form-group.validate-form #resetFormButton {
		display: block;
	}
	
	.form-group.validate-form .correct-answer {
		display: block;
	}
	
	h3, h2, > p {
		text-align: center;
	}

	.pull-quote {
		font-size: 30px;
		color: @color-brand;
	}

	h3 {
		color: @color-text;
	}

	figure img {
		max-width: 100%;
	}
}

.exam-image {
	text-align: center;
}

.practice-exam {
	margin: @spacing-large 0;
	
	li:not(:first-child) {
		margin-top: 60px;
	}
}

.practice-exam__question {
	font-size: 24px;
	line-height: @line-height-pct;
	
	fieldset {
		border: 0;
		
		@media (max-width: @screen-tablet) {
			padding: 0;
		}
	}
	
	.practice-exam__prompt {
		margin-bottom: @spacing-standard;
		
		@media (max-width: @screen-tablet) {
			font-size: 18px;
			
			.imageplugin td {
				padding: 0;
			}
			
			.imageplugin img {
				width: 100%;
			}
		}
	}
	
	.practice-exam__prompt figure {
		margin: @spacing-standard 0 0 0;
		text-align: center;
		
		@media(min-width: @screen-mobile) {
			text-align: left;
		}
	}

	.practice-exam__options {
		grid-area: ~"2 / 2 / 3 / 3";
		.plain-ul();
		.font-body();

		@media(min-width: @screen-mobile) {
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
			grid-gap: @spacing-standard;
		}

		figure {
			margin: 0;
		}

		li {
			margin: 0;
			
			&:not(:first-child) {
				margin-top: @spacing-standard;

				@media (min-width: @screen-mobile) {
					margin: 0
				}
			}
			
			.radio-row {
				display: flex;
				grid-column-gap: 5px;
				
				@media (max-width: @screen-mobile) {
					.MathJax, .mjx-chtml {
						font-size: 16px !important;
					}
				}
			}
			
			.correct-answer {
				display: none;
				color: @color-practice-exam-success;
				font-size: 13px;
				text-align: center;
			}
		}
	}
}

.horizontal-line {
	margin-top: 30px;
	border-top: 1px solid #E1E1E1;
}

.hidden {
	display: none;
}

.passage {
	padding: @spacing-standard;
	margin: 20px auto;
	
	.instructions {
		font-weight: @font-weight-bold;
		font-size: 20px;
		text-align: center;
		padding-bottom: 20px;
		display: block;
	}
	
	p {
		font-size: 18px;
	}
}

.practice-exam {
	list-style: none;
	counter-reset: list;
	
	@media (max-width: @screen-tablet) {
		padding: 0 20px;
	}
}

.practice-exam__question {
	position: relative;
}

.practice-exam__question:before {
	counter-increment: list;
	content: counter(list)'.';
	position: absolute;
	right: 100%;
	margin-right: 5px;
	top: 0;
	
	@media (max-width: @screen-tablet) {
		font-size: 18px;
	}
}
