@import (reference) "../../mixins/_styleResets";

.exam-progress-bar {
	.gradient(#DAECF1, @color-white);
	position: relative;
	display: flex;
	align-items: center;
	height: 35px;
	border: 1px solid @color-gray-2;
	border-radius: 17px;
	overflow: hidden;
}

.exam-progress-bar__answered {
	position: absolute;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	color: @color-gray-5;
	font-size: 12px;
	font-weight: @font-weight-semibold;
}

.exam-progress-bar__progress {
	position: relative;
	margin: 0 4px;
	height: 86%;
	background: linear-gradient(0deg, #3C6A57 -27.08%, #3DC58D 97.92%);
	border: 1px solid @color-gray-1;
	border-radius: 17px;
	overflow: hidden;
	transition: width .25s;
}

.exam-progress-bar__progress--not-started {
	border: none;
}
