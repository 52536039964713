@import (reference) "../_variables";
@import (reference) "../../mixins/_styleResets";

.hero {
	background-color: @color-blue-5;
	background-image:
	 url("/resources/img/77/blue-shirt-teacher-xs.png"),
	 url("/resources/img/77/people-sm.png"),
	 url("/resources/img/77/orange-blossom-lg.svg"),
	 url("/resources/img/77/orange-blossom-sm.svg");

	background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;

	background-size: ~"calc(min(438px, 30%))", auto, 275px, 240px;

	background-position:
	 right ~"calc(max(95px, ((100vw - @{max-width-hero}) / 2) + 95px))" bottom,
	 bottom,
	 top 40px right -60px,
	 bottom 70px left -30px;

	@media (min-width: @screen-tablet) {
		background-image: url("/resources/img/77/blue-shirt-teacher.png"),
		url("/resources/img/77/people-lg.png"),
		url("/resources/img/77/orange-blossom-lg.svg"),
		url("/resources/img/77/orange-blossom-sm.svg");

		background-size: ~"calc(min(438px, 30%))", auto, 813px, 539px;

		background-position:
		 right ~"calc(max(95px, ((100vw - @{max-width-hero}) / 2) + 95px))" bottom,
		 bottom,
		 top 40px right ~"calc(min(300px, -270px - @{screen-laptop} + 100vw))",
		 bottom -100px left ~"calc(max(-130px, ((100vw - @{max-width-hero}) / 2) - 130px))";
	}

	padding-bottom: 220px;

	h1 {
		font-size: 36px;

		@media(min-width: @screen-tablet) {
			font-size: 48px;
		}

		line-height: @line-height-pct;
	}

	text-align: left;

	section.content > * {
		max-width: ~"calc(min(100%, 680px))";
	}
}

main {
	padding-bottom: 0;
}

ul.top-feature-buttons {
	.plain-ul();
	display: grid;
	grid-column-gap: @spacing-standard;
	grid-row-gap: @spacing-paragraph;

	grid-template: auto / auto;

	@media (min-width: @screen-tablet) {
		grid-template: auto / repeat(auto-fit, minmax(115px, 1fr));
	}

	li:not(.link-inside), li a {
		padding: @spacing-paragraph;
		display: grid;
		grid-template: auto / 4fr 6fr;
		place-items: center;
		grid-gap: @spacing-minimum;
		font-weight: @font-weight-bold;
		border: 1px solid @color-blue-3;
		border-radius: 6px;

		span {
			justify-self: start;

			@media (min-width: @screen-tablet) {
				justify-self: center;
				text-align: center;
			}
		}

		@media (min-width: @screen-tablet) {
			grid-template: auto auto / auto;
			border: none;
		}
	}
}

#big-features {
	ul {
		.plain-ul();
		display: grid;
		grid: auto-flow / auto;
		grid-gap: 1px;

		background-color: @color-gray-8;

		li {
			padding: @spacing-standard 0;

			&:first-child {
				padding-top: 0;
			}

			&:last-child {
				padding-bottom: 0;
			}

			background-color: white;
			br {
				display: none;
			}
		}

		@media (min-width: @screen-tablet) {
			grid: auto / auto-flow;
			grid-gap: 0;

			li {
				padding: 0 @spacing-standard;

				&:first-child {
					padding-left: 0;
				}

				&:last-child {
					padding-right: 0;
				}

				br {
					display: block;
				}
			}
		}

	}
}

#ftce-faqs ul {
	.plain-ul();
	display: grid;
	background-color: @color-brand;
	grid: auto-flow / auto;
	grid-gap: 1px;

	@media (min-width: @screen-tablet) {
		grid: auto / auto-flow;
	}

	li {
		display: grid;
		padding: @spacing-standard 0;
		background-color: @color-blue-gray;

		@media (min-width: @screen-tablet) {
			padding: 0 @spacing-standard;
		}

		button, a.button {
			align-self: end;
			width: 100%;
			margin-left: auto;
			margin-right: auto;
		}
	}
}

.testimonial-bleed {
	background-color: @color-gold-2;
	background-image: url("/resources/img/77/footer-image-xs.jpg");

	@media (min-width: @screen-tablet) {
		padding-top: @spacing-large;
		padding-bottom: @spacing-large;

		background-image: url("/resources/img/77/footer-image-lg.jpg");
	}

	background-size: auto;
	background-position: bottom center;
	background-repeat: no-repeat;

	padding-top: @spacing-standard;
	padding-bottom: @spacing-standard;
}

#testimonials {
	ul {
		.plain-ul();

		@media (min-width: @screen-tablet) {
			column-count: 2;
		}

		li {
			break-inside: avoid;
			&:not(:last-child) {
				margin-bottom: 45px;
			}
		}
	}
}