@import (reference) "../../mixins/_styleResets";
@import (reference) "../../mixins/_icons.less";

.expert-contributions(@person-color, @person-background-color, @button-color) {
	@person-icon: '\e7fd';
	@profile-img-width: 64px;
	
	& {
		margin-top: @spacing-standard;
	}
	
	.seoKeywords {
		text-transform: capitalize;
	}
	
	h3 {
		margin-top: @spacing-paragraph;
	}
	
	ul {
		list-style-position: inside;
	}
	
	.questions {
		margin-top: @spacing-paragraph;
		padding-left: @spacing-paragraph;
		
		li {
			font-weight: @font-weight-bold;
		}
	}
	
	h4 {
		font-weight: @font-weight-bold;
	}
	
	.expert-contributors {
		padding: 0;
		list-style-type: none;
		margin-top: 30px;
		
		.expert-contributor {
			display: flex;
			flex-direction: column;
			grid-row-gap: @spacing-paragraph;
			
			.expert-details {
				display: flex;
				grid-column-gap: @spacing-paragraph;
				min-height: @profile-img-width;
				
				.name {
					font-family: @font-family-header;
					font-weight: @font-weight-bold;
				}
				
				.title {
					margin: 0;
					font-size: 16px;
				}
			}
			
			.expert-bio-and-contributions {
				@media (min-width: @screen-tablet) {
					padding-left: @profile-img-width + @spacing-paragraph;
				}
				
				.expert-contributor__contributions > ul {
					list-style: none;
					padding: 0;
				}
				
				.toggle-function(@button-color);
			}
		}
		
		.expert-contributor:not(:first-child) {
			margin-top: @spacing-large;
		}
	}
	
	.expert-img {
		width: @profile-img-width + 1px;
	}
	
	.person-icon:before {
		.material(@person-icon);
		font-size: @profile-img-width;
		color: @person-color;
		background-color: @person-background-color;
		position: relative;
		top: 20px;
	}
}

.expert-contributions--compact(@person-color, @person-background-color, @button-color) {
	@person-icon: '\e7fd';
	@profile-img-width: 130px;
	
	& {
		margin-top: 50px;
	}
	
	.expert-contributors {
		padding-left: 0;
	}
	
	.expert-contributor {
		list-style-type: none;
		padding-bottom: 50px;
		
		&:last-child {
			padding-bottom: 0;
		}
	}
	
	.seoKeywords {
		text-transform: capitalize;
	}
	
	.questions {
		display: grid;
		grid-template-columns: 1fr;
		gap: 20px;
		margin-top: 25px;
		padding-left: @spacing-paragraph;
		
		@media (min-width: @screen-tablet) {
			grid-template-columns: repeat(2, 1fr);
			margin-top: 50px;
		}
	}
	
	.questions__item {
		margin-left: 1em;
		font-weight: @font-weight;
		font-size: @font-size-lg;
	}
	
	.expert-contributions__header {
		.font-h3();
	}
	
	.expert-details {
		display: grid;
		grid-template-columns: auto 1fr;
		grid-template-rows: repeat(2, auto);
		grid-template-areas: "image details"
									"bio bio";
		align-items: center;
		gap: 20px @spacing-standard;
		
		@media (min-width: @screen-tablet) {
			grid-template-areas: "image details"
										"image bio";
			align-items: start;
		}
	}
	
	.expert-img {
		grid-area: image;
		width: @profile-img-width;
		aspect-ratio: 1 / 1;
		object-fit: cover;
		border-radius: 5px;
		overflow: hidden;
	}
	
	.expert-info {
		grid-area: details;
	}
	
	.expert-bio-and-contributions {
		grid-area: bio;
		.toggle-function(@button-color);
	}
	
	.expert-contributor__contributions .view-more-wrap {
		list-style: none;
		padding-left: 0;
	}
	
	.expert-contributor__contributions .view-more-trigger {
		margin-top: 30px;
	}
	
	.person-icon:before {
		.material(@person-icon);
		font-size: @profile-img-width;
		color: @person-color;
		background-color: @person-background-color;
		position: relative;
		top: 20px;
	}
}

.toggle-function(@button-color) {
	.view-more-state {
		display: none;
	}
	
	.view-more-target {
		opacity: 0;
		max-height: 0;
		font-size: 0;
		transition: .25s ease;
	}
	
	.view-more-state:checked ~ .view-more-wrap .view-more-target {
		opacity: 1;
		font-size: inherit;
		max-height: 999em;
		margin-top: @spacing-paragraph;
	}
	
	.view-more-state ~ .view-more-trigger:after {
		.material('\e5cf');
		vertical-align: middle;
		color: @color-white;
		background-color: @button-color;
		margin-left: 5px;
		font-weight: 600;
	}
	
	.view-more-state ~ .view-more-trigger:before {
		content: 'View more';
		vertical-align: middle;
	}
	
	.view-more-state:checked ~ .view-more-trigger:before {
		content: 'View less';
	}
	
	.view-more-state:checked ~ .view-more-trigger:after {
		.material('\e5ce');
	}
	
	.view-more-trigger {
		cursor: pointer;
		display: inline-block;
		margin-top: @spacing-paragraph;
		color: @button-color;
		font-weight: @font-weight-bold;
		position: relative;
		z-index: 1;
	}
}
