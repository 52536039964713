.dial-gauge(@dialSize; @backgroundDialColor; @islandBackgroundColor) {
	dial-gauge {
		display: block;
		position: relative;
		width: @dialSize;
		height: @dialSize;
		margin: 0 auto;
	}
		
	.dial-gauge {
		width: @dialSize;
		height: @dialSize;
	}
	
	.dial-gauge__svg {
		display: block;
		margin: 0 auto;
		width: @dialSize;
		height: @dialSize;
		overflow: visible;
		
		.background {
			fill: @backgroundDialColor;
		}
		
		.island {
			fill: @islandBackgroundColor;
		}
		
		~ * {
			position: absolute;
			top: 50%; // compared to relative-parent
			left: 50%; // compared to relative-parent
			-webkit-transform: translateX(-50%) translateY(-50%); // compared to self
			transform: translateX(-50%) translateY(-50%); // compared to self
			text-align: center;
		}
	}
}
.dial-gauge(228px, #fff, #fff);
